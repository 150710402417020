.filepond--credits {
  display: none;
}

.filepond--panel-root{
  background-color: transparent !important;
}

html.dark{
  .filepond--drop-label {
    color: #fff;
  }
}